.mensajesPrincipal{
    background-color: #FFFFFF;
    border-radius: 4px;
    border: 1px solid #E4E5E6;
    /* height: 68vh; */
    height: 650px;
}
/* -------------------- Divisiones izquierda y derecha -------------------- */
.panelIzquierdo{
    width: 350px;
    max-width: 500px;
}

.panelDerecho{
    width: 100%;
}
/* -------------------------- Header y buscador --------------------------- */
.mensajesHeader{
    display: flex;
    background-color: #EFF4FD;
    border-bottom: 1px solid #E4E5E6;
    padding: 8px;
}

.mensajesHeader .panelIzquierdo{
    padding-right: 16px;
}
/* ------------------------------- Acciones ------------------------------- */
.mensajesAction{
    display: flex;
    background-color: #FFFFFF;
    border-bottom: 1px solid #E4E5E6;
    min-height: 52px;
    padding: 8px;
}

.buttonsActions{
    float: right;
}
/* ------------------------- Bandejas & Contenidos ------------------------ */
.mensajesContenido{
    display: flex;
}
/* ----------------------- Bandejas (Entrada /Salida) ---------------------- */
.mensajesBandeja{
    height: 530px;
    overflow-y: scroll;
}
.mensajesVista{
    height: 530px;
    overflow-y: auto;
}
/* ------------------------------------------------------------------------- */
/* Bandeja Entrada */
.mensajeEntrada{
    padding: 8px;
    cursor: pointer;
    user-select: none;
    border-bottom: 1px solid #B6B7B7;
}

.mensajeEntrada:hover {
    background-color: #E0EAFA;
}

/* Bandeja Salida */
.mensajeSalida {
    padding: 8px;
    cursor: pointer;
    user-select: none;
    border-bottom: 1px solid #B6B7B7;
}

.mensajeSalida:hover {
    background-color: #E0EAFA;
}

/* ---------------------- Contenidos (Vista Mensajes) ---------------------- */
/* -- Contenido Selecciona un elemento */
.mensajesContenidoVacio{
    height: 100%;
    text-align: center;
    padding-top: 150px;
    color: #B6B7B7;
    background-color: #F5F6F7;
}

.mensajesContenidoVacio .MuiSvgIcon-root{
    font-size: 7rem !important;
}

/* -- Contenido Enviar mensaje */
.mensajesVista .enviarMensaje{
    height: 100%;
}

.enviarMensaje .MuiFormControl-root{
    width: 100%;
}

.enviarMensaje .MuiOutlinedInput-root{
    /* border-radius: 4px 4px 0px 0px; */
    border-radius: 0px;
}

.enviarMensaje .MuiAutocomplete-inputRoot::before{
    content: "Para";
    font-size: .85rem;
    font-weight: bold;
    background-color: #EFF4FD;
    color: #1572E8;

    padding: 2px 8px 2px 8px;
    margin: 0px 4px 0px 4px;
    border-radius: 4px;
}

/* -- Contenido Mostrar Mensaje */
.mostrarMensaje{
    height: 100%;
}

.mostrarMensaje .asuntoMensaje{
    padding: 10px 20px 10px 20px;
    font-size: 0.8rem;
    font-weight: bold;
    border-bottom: 1px solid #E4E5E6;
}

.mostrarMensaje .informacionMensaje{
    padding: 15px 20px 15px 20px;
}

.mostrarMensaje .cuerpoMensaje{
    padding: 15px 20px 15px 64px;
}

.mostrarMensaje .cuerpoMensaje > p{
    margin-bottom: 0px;
}