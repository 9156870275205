@font-face{
    font-family: "Lato";
    src: url(../Media/Lato/Lato-Regular.ttf); 
    /*font-family: "Lato";
    src: url('../Media/Univia/univiapro-regular-webfont.woff2') format('woff2'),
    url('../Media/Univia/univiapro-regular-webfont.woff') format('woff');*/
}
@font-face{
    font-family: "calibril";
    src: url(../Media/calibril.ttf);
}
body{
    line-height: 1;
}

textarea {
    resize: none !important;
}

.invalid-feedback {
    margin: 0 !important;
}

.linkF{
    display:inline;
    border:0;
    padding:0;
    margin:0;
    text-decoration:underline;
    background:none;
    color:#000088;
    font-family: arial, sans-serif;
    font-size: 1em;
    line-height:1em;
}

.btn1-3 {
    min-width: 10px !important;
    width: 30% !important;
    height: 85%;
    padding: 0.4rem 0 !important;
    text-transform: capitalize !important;
    font-size: 14px !important;
    font-family: "Lato" !important;
    font-weight: bold!important;
}

.btn1-3-right {
    background-color: #1572E8 !important;
    border-color: #1572E8 !important;
    color: #ffffff !important;
}

.btn1-3-center {
    margin: 0 5% !important;
    background-color: #ffffff !important;
    border-color: #1572E8 !important;
    color: #1572E8 !important;
}

.btn1-3-left {
    background-color: #ffffff !important;
    border-color: #4C638E !important;
    color: #4C638E !important;
}

.label-cell {
    width: 100%;
    height: 90%;
    display: flex;
    align-items: center;
    background-color: #F8F8F8;
    padding: 0.4rem;
}

.contenedor-botones-calculo {
    width: 30%;
    display: flex;
    align-items: initial;
    float: left;
    padding: 0.2rem;
}

.label-block {
    background-color: #E4E5E6 !important;
}

.btn-color{
    width: 100%;
    border:2px solid #1572E8 !important;
    background-color: #1572E8 !important;
    color: #ffffff !important; 
    padding:0.4rem !important;
    text-transform: capitalize !important;
    font-size: 14px !important;
    margin-top: 0.375rem !important;
    margin-bottom: 0.375rem !important;
    font-family: "Lato" !important;
    font-weight: bold!important;
}

.textGreen {
    color: #697B9F;
}

.s{
    padding-right: 16px!important;
}

.btn-color:focus{
    color: white !important;
    background-color:#fff;
    outline:0!important;
    box-shadow:0 0 0 .2rem rgba(76, 99, 142, 0.15)!important;    
}

.btn-color:hover{
    background-color: #1572E8!important;
    color: white !important;
    border:2px solid #1572E8 !important;
}

.btn-color:disabled{
    background-color: #E4E5E6!important;
    border: 2px solid #E4E5E6!important;
    font-weight: bold!important;
    color: #757575!important;
}

.react-joyride__tooltip button:hover {
         background-color:#1572E8 !important;
         color: white!important;
         border-radius: 5px!important;
         
}

.react-joyride__tooltip button {
    color: #1572E8!important;
    border-radius: 5px!important;
    border: 1px solid #1572E8!important;
    font-family: "Lato";
}

.react-joyride__tooltip button span {
    color: white!important;
    font-family: "Lato";
}

.btn-outline{
    width:100%;
    margin-bottom: 1rem !important;
    color: #697B9F !important;
    background-color: #ffffff !important;
    text-transform: capitalize !important;
    font-size: 14px !important;
    padding:0.4rem !important;
    margin-top: 0.375rem !important;
    margin-bottom: 0.375rem !important;
    text-decoration:none!important; 
    border: 2px solid #697B9F !important;
    font-family: "Lato"!important;
    font-weight: bold!important;
}

.space{
    margin-left: 10px!important;
}

.btn-outline:focus{
    color: #697B9F !important;
    background-color:#ffffff;
    outline:0!important;
}

.btn-outline:disabled{
    background-color: #E4E5E6!important;
    border: 2px solid #E4E5E6!important;
    font-weight: bold!important;
    color: #757575!important;
}

.btn-outline:hover{
    color: #ffffff !important;
    background-color:#697B9F!important;
    outline:0!important;
   font-family: "Lato";
   font-weight: bold;
    border: 2px solid #697B9F !important;
}

.btn-color-form{
    border-color: #697B9F !important;
    background-color: #697B9F !important;
    color: white !important; 
    text-transform: capitalize !important;
}

.btn-color-form:focus{
    color: white !important;
    background-color:#fff;outline:0!important;
    box-shadow:0 0 0 .2rem rgba(12, 81, 231, 0.25)!important;
}

.btn-color-form:hover{
    box-shadow:0 0 0 .2rem rgba(12, 81, 231, 0.3)!important;
    color: white !important;
}

.btn-outline-form{
    border:1.6px solid #697B9F !important;
    background-color:#fff !important;
    outline:0!important;
    color: #697B9F !important;
    text-transform: capitalize !important;
}

.btn-outline-form:focus{
    color: #697B9F !important;
    background-color:#fff;
    outline:0!important;
    box-shadow:0 0 0 .2rem rgba(12, 81, 231, 0.25)!important;
}

.btn-outline-form:hover{
    background-color:rgba(12, 81, 231, 0.05)!important;
}

.div-text{
    width:100%;
    border: none !important;
    margin-bottom: .5rem !important;
    text-transform: capitalize !important;
    font-size: 0.9rem !important;
    padding:0.4rem !important;
    text-align: center;
}

.div-text:hover{
    cursor: default;
}

.btn-text{
    width:100%;
    border: none !important;
    margin-bottom: .7rem !important;
    color: #697B9F !important;
    text-transform: capitalize !important;
    font-size: 0.9rem !important;
    padding:0.4rem !important;
}

.btn-text:focus{
    color: #697B9F !important;
    background-color:#fff;outline:0!important;
    box-shadow:0 0 0 .2rem rgba(12, 81, 231, 0.25)!important;
}

.btn-text:hover{
    cursor: default;
    background-color:rgba(12, 81, 231, 0.05)!important;
    color: #125896;
}

.btn-modal{
    width: 7rem;
}

.col-login{
    padding-right: 0px !important;
    padding-left: 0px !important;
}

.subrayado{
    text-decoration: underline;
}

.contenedor-checkbox{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: .7rem !important;
    margin-bottom: .3rem !important;
}

.link{
    margin-top: .4rem;
    width: 100% !important;
    font-size: 14px !important;
    color: #393638 !important;
    background-color: transparent;
	border: 0;
	width: auto;
    height: auto;
    font-family: "Lato" !important;
}

.link:hover{
    cursor: pointer;
    color: #697B9F !important;
}

/*iconos del tooltip*/
.tooltipicon {
    color: #aaa9a9;
    font-size: 1.1rem;
    margin: 0;
    padding: 0;
    position: relative;
    bottom: -3px
}

.label-form{
    font-size: 14px !important;
    color: #393638 !important;
    font-weight: bold;
    font-family: "Lato";
    font-weight: bold;
}

.form-group{
    margin: .5rem 0 !important;
}
.form-desc{
    margin: .5rem 0 !important;
    height: 73%;
    padding-bottom: 2%;
}

/* *** Formulario *** */
.modal-style{
    width: 20%!important;
    margin: 0 auto!important;
}

.form-left {
    flex: 1 0 auto;
    width: 50%;
    padding-right: 1rem;
}

.form-right {
    width: 50%;
    padding-left: 1rem;
}

/* *** Formulario Tipo de Relación laboral*** */
.formRelLab{
    display: flex;
    flex-wrap: wrap;
}

.claNom{
    flex: 1 0 auto ;
    margin-right: 35px;

}
.descrip{
    margin-right: 35px;
}

.descripcion{
    height: 100%;
    width: 100%;
    font-size:1rem;
    line-height:1.5;
    color: #2a2a2a !important;
    background-color:#f8f8f8 !important;
    background-clip:padding-box;
    border: 2px solid #D5D3D3;
    border-radius:0.25rem;
    font-family: 'calibril';

}
.descripcion::-ms-expand{
    background-color:transparent;
    border:0;
}
.descripcion:focus{
    color: #7a7a7a !important;
    background-color:#ffffff !important;
    outline:0!important;
    /*border: 2px solid #a39898 !important;*/
    border: 2px solid #818282;
    box-shadow: 0px 0px 10px -1px rgba(129,130,130,0.75)!important;
}
.descripcion::placeholder{
    color: #2a2a2a;
    opacity: 0.6;
    font-size: 14px;
}
.descripcion:disabled{
    background-color:rgb(189, 226, 247)!important;
    opacity:0.5;
}
.descripcion:required{
    background-color: #1572E8;
}


.descripcionShort{
    height: 50px;
    width: 100%;
    font-size:1rem;
    line-height:1.5;
    color: #2a2a2a !important;
    background-color:#f8f8f8 !important;
    background-clip:padding-box;
    border: 2px solid #D5D3D3;
    border-radius:0.25rem;
    font-family: 'calibril';

}
.descripcionShort::-ms-expand{
    background-color:transparent;
    border:0;
}
.descripcionShort:focus{
    color: #7a7a7a !important;
    background-color:#ffffff !important;
    outline:0!important;
    border: 2px solid #818282;
    box-shadow: 0px 0px 10px -1px rgba(129,130,130,0.75)!important;
}
.descripcionShort::placeholder{
    color: #2a2a2a;
    opacity: 0.6;
    font-size: 14px;
}
.descripcionShort:disabled{
    background-color:rgb(189, 226, 247)!important;
    opacity:0.5;
}
.descripcionShort:required{
    background-color: #1572E8;
}

.showContent:hover {
    background-color: #1572E8!important;
}

.btn-selected {
    width: 3.2rem;
    height: 2.6rem;
    border-radius: 4px;
    color: #ffffff;
    background-color: #1572E8;
    border: 2px solid #1572E8;
}

.btn-infoAdicional {
    width: 3.2rem;
    height: 2.6rem;
    border-radius: 4px;
    color: #1572E8;
    background-color: #ffffff;
    border: 2px solid #1572E8;
}

.btn-infoAdicional:hover {
    color: white;
    background-color: #1572E8;
    border: 2px solid #1572E8;
}

.btn-removeItem-outlined {
    position: absolute;
    right: 15px;
    width: 1.6rem;
    height: 1.6rem;
    border-radius: 4px;
    color: #F25A5A!important;
    background-color: transparent!important;
    border: 2px solid transparent!important;
    padding: 0 !important;
}

.btn-removeItem-service {
    right: 40px !important;
}

.btn-editItem-outlined {
    position: absolute;
    right: 15px;
    width: 1.6rem;
    height: 1.6rem;
    border-radius: 4px;
    color: #697B9F!important;
    background-color: transparent!important;
    border: 2px solid transparent!important;
    padding: 0 !important;
}

.btn-removeItem-outlined:hover {
    color: #ffffff!important;
    background-color: #F25A5A!important;
    border: 2px solid #F25A5A!important;
    font-weight: bold!important;
}

.btn-editItem-outlined:hover {
    color: #ffffff!important;
    background-color: #697B9F!important;
    border: 2px solid #697B9F!important;
    font-weight: bold!important;
}

.avatar-addPhotoEmp {
    float: right;
    margin-top: 1.8rem;
    margin-right: 1rem;
}

.btn-addPhotoEmp {
    float: right;
    margin-top: 1.8rem;
    width: 3.2rem;
    height: 2.6rem;
    border-radius: 4px;
    color: white!important;
    background-color: #1572E8!important;
    border: 2px solid #1572E8!important;
    padding: 4px 10px !important;
}

.btn-addPhotoEmp:hover {
    color: white!important;
    background-color: #98B6ED!important;
    border: 2px solid #98B6ED!important;
    font-weight: bold!important;
}

.btn-autocompletecancelInput,
.btn-autocompleteInput,
.btn-autocompleteSueldo,
.btn-autocompletecancel,
.btn-autocomplete {
    float: right;
    margin-top: 36px;
    width: 3.2rem;
    height: 2.6rem;
    border-radius: 4px;
    color: white!important;
    background-color: #1572E8!important;
    border: 2px solid #1572E8!important;
    padding:0.3rem !important;
}

.btn-autocompleteSueldo {
    margin-top: 0px !important;
}

.btn-autocompletecancel {
    background-color: rgb(240, 107, 107)!important;
    border: 2px solid rgb(240, 107, 107)!important;
}

.btn-autocompletecancelInput {
    background-color: rgb(240, 107, 107)!important;
    border: 2px solid rgb(240, 107, 107)!important;
    margin-bottom: 10px !important;
}

.btn-third {
    color: white!important;
    background-color: #1572E8!important;
    border: 2px solid #1572E8!important;
    font-family: "Lato"!important;
    font-weight: bold!important;
    text-transform: capitalize!important;
    padding:0.4rem !important;
    font-size: 14px !important;
}

.btn-autocompleteInput:hover,
.btn-autocompleteSueldo:hover,
.btn-autocomplete:hover,
.btn-third:hover {
    color: white!important;
    background-color: #98B6ED!important;
    border: 2px solid #98B6ED!important;
    font-weight: bold!important;
}

.btn-autocompletecancelInput:hover,
.btn-autocompletecancel:hover {
    color: white!important;
    background-color: rgb(255, 146, 146)!important;
    border: 2px solid rgb(255, 146, 149)!important;
    font-weight: bold!important;
}

.btn-autocompleteInput:disabled,
.btn-autocompleteSueldo:disabled,
.btn-autocomplete:disabled,
.btn-third:disabled {
    background-color: #E4E5E6!important;
    border: 2px solid #E4E5E6!important;
    font-weight: bold!important;
    color: #757575!important;
}

.btn-with {
    width: 100% !important;
}

.btn-auto {
    width: auto !important;
}

::-webkit-scrollbar-thumb {
        width: 8px;     /*Tamaño del scroll en vertical */
        height: 8px;    /* Tamaño del scroll en horizontal */
        display: none;  /* Ocultar scroll */
        background: #697B9F ;
} 

label, .label{
    font-family: "Lato";
    font-size: 14px;
}


.label-form .requerido {
    margin-bottom: 0px!important;
}

.requeridoWhitout {
    height: 15px;
}

h4,
.requerido{
    color: #697B9F;
    font-family: "Lato";
    font-weight: bold;
}
h4{
    font-size: 1.313rem!important;
}

h5{
    color: #697B9F;
    font-family: "Lato";
}
                
.without {
    color: #000000 !important;
    font-family: arial;
    font-weight: bolder !important;
}

.TableroDataTable .MuiToolbar-root{
    background-color: #EFF4FD!important;
}
.Component-paginationToolbar-370{
    background-color: #EFF4FD!important;
}
.MuiTablePagination-toolbar{
    background-color: #EFF4FD!important;
}
.headermodal{
    width: 100%;
    height: auto;
    text-align: center;
    background: red;
}

.Footermodalaceptar{   
    width: 50%;
    height: auto;
    float: right;  
    text-align: right;
}

.Footermodalcancelar{
    width: 50%;
    height: auto;
    float: left;  
    text-align: left;
}

.tooltip-inner {
    max-width: 300px !important;
    background-color: #63676F !important;
    color: white !important;
}

.arrow::before {
    border-right-color: transparent !important;
    border-left-color: transparent !important;
    border-bottom-color: transparent !important;
    border-top-color: transparent !important;
    color: white !important;
}

/*  Modal de seleccionar tipo de relación laboral  */
.contenedorModalRelLab {
    z-index: 14999 !important
}

.contenedorTerminosPrivacidad {
    z-index: 15000 !important
}
 
@media all and (max-width: 805px ){
    .descrip{
        flex: 0 0 auto ;
        margin-left: 0px;
    }
}

.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    margin-top: 7px;
    height: 34px;
  }
  
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  input:checked + .slider {
    background-color: #4C638E;
  }
  
  input:focus + .slider {
    box-shadow: 0 0 1px #4C638E;
  }
  
  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }
  
  /* Rounded sliders */
  .slider.round {
    border-radius: 5px;
  }
  
  .slider.round:before {
    border-radius: 50%;
  }

.MuiFormControl-marginNormal {
    margin-top: 9px !important;
    margin-bottom: 10px !important;
}

.spanInfo .MuiSvgIcon-root {
    width: 1rem !important;
    height: 1rem !important;
    position: absolute;
    margin-top: 3px;
    margin-left: 7px!important;
}

th .spanInfo .MuiSvgIcon-root {
    width: 1rem !important;
    height: 1rem !important;
    position: absolute;
    margin-top: 12px;
    margin-left: 7px!important;
}

.spanInfoSelect .MuiSvgIcon-root {
    height: 1rem !important;
    height: 1rem !important;
}

/*ESTILOS RADIOBUTONS*/
.labelRadio {
    display: block;
    position: relative;
    padding-left: 30px;
    margin-bottom: 12px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  
  /* Hide the browser's default radio button */
  .labelRadio input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }
  
  /* Create a custom radio button */
  .spanRadio {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: white;
    border: 1px solid #d5d3d3;
    border-radius: 50%;
  }
  
  /* On mouse-over, add a grey background color */
  .labelRadio:hover input ~ .spanRadio {
    background-color: white;
    border: 1px solid #d5d3d3;
  }
  
  /* When the radio button is checked, add a blue background */
  .labelRadio input:checked ~ .spanRadio {
    background-color: white;
    border: 1px solid #d5d3d3;
  }
  
  /* Create the indicator (the dot/circle - hidden when not checked) */
  .spanRadio:after {
    content: "";
    position: absolute;
    display: none;
  }
  
  /* Show the indicator (dot/circle) when checked */
  .labelRadio input:checked ~ .spanRadio:after {
    display: block;
  }
  
  /* Style the indicator (dot/circle) */
  .labelRadio .spanRadio:after {
       top: 2px;
      left: 2px;
      width: 14px;
      height: 14px;
      border-radius: 50%;
      background: #1572E8;
  }
  .MuiAutocomplete-option[aria-disabled="true"]{
    opacity: 1!important;
  }
  .MuiTableCell-paddingCheckbox:last-child{
    text-align: left!important;
    padding: 1rem!important;
}
.generalSearch[x-placement="top-start"]{
    margin-top: 49.5rem!important; 
}
button[aria-label="change date"] {
    margin: 20px 0px!important;
}
input[id="date-picker-inline"]{
    font-family: 'calibril';
}
.MuiInputAdornment-positionEnd{
    margin-left: 0px!important;
}

.form-control.is-valid, .was-validated .form-control:valid {
    padding-right: calc(1em + .75rem) !important;

}
.MuiChip-root{
    background-color: #4C638E!important;
    color: #ffffff!important;
}
.MuiChip-deleteIcon{
    color: #ffffff!important;
}
.report{
    position: absolute;
} 

.cardBoard .info {
    font-size: 25px !important;
}
.MuiCardActions-root{
    display: block!important;
}
.MuiCardActions-root:hover{
    background-color: rgba(0, 0, 0, 0.05)!important;
}

/*   Boton addFoto Empleados    */
.photoIcon {
    background-color: #1572E8 !important;
}
.photoIcon:hover {
    background-color: #98B6ED !important;
}
/*---------*/

/*ESTILOS COMPONENTE SELECTVALIDATION*/
.MuiFilledInput-input{
    padding: 10px 12px 10px!important;
    background-color:#f8f8f8 !important;
    width:100%; 
    font-size: 14px!important; 
    color: #2a2a2a !important; 
    background-clip:padding-box;
    border: 2px solid #D5D3D3!important;
    border-radius:0.5rem;
    transition:border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    font-family: 'calibril'!important;
}
.MuiFilledInput-inputValidated{
    padding: 10px 12px 10px!important;
    background-color:#f8f8f8 !important; 
    font-size: 14px!important; 
    color: #2a2a2a !important; 
    background-clip:padding-box; 
    border-radius:0.25rem!important;
    transition:border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    font-family: 'calibril'!important;
}  
.is-invalidSelect {
    border:1.8px solid rgb(224, 15, 15) !important;
    border-radius:0.25rem!important;
} 
.is-validSelect div, .is-invalidSelect div{
    border: 0px solid #D5D3D3!important;
}  
.is-invalidSelect:focus{
    color: #7a7a7a !important;
    background-color:#fff;
    outline:0!important;
    box-shadow:0 0 0 .2rem rgba(206, 36, 36, 0.25)!important;
    border:1.8px solid rgb(224, 15, 15) !important;

}
.is-validSelect {
    border:1.8px solid rgb(13, 179, 13) !important;
    border-radius:0.25rem!important;
}
.MuiSelect-selectMenu {
    height: auto;
    overflow: hidden;
    min-height: 1.1876em;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 100%;
}
.selectOption{
    font-size: 14px!important; 
    color: #2a2a2a !important; 
    font-family: 'calibril'!important;
}
.MuiSelect-select.Mui-disabled{
    background-color:#e4e5e6!important;
    color:gray!important;
}
.MuiPopover-root{
    position: fixed;
    z-index: 1000000!important;
    inset: 0px;
}
.MuiSelect-select{
    border-radius: 0.25rem!important;
}
.selectOption:hover{
    background-color:#DFE9FC!important;
}

/*---------*/