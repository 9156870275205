.centerImg {
    margin: auto;
    display: block;
    /*border: 4px solid rgb(213, 236, 241);*/
}

/* Estilos de <input type="file" > */
.imagen_button_container {
    display:inline-block;
    position:relative;
    text-align: center;
}

.image-upload > input {
    display: none;
}

.image-upload label{
    text-align: center;
    cursor: pointer;
    background-color: #4C638E;
    color: white;

    position: absolute;
    bottom: 1.4em;
    margin-left: 1.3em;
    border: 3px solid rgb(255, 255, 255);
}

.image-upload label:hover{
    opacity: 0.9;
    background-color: #4C638E;
    color: white;
}

@media (max-width: 600px) {
    .modal {
        padding-left: 0px;
    }
}

#ModalCrop .modal-footer{
    display: block;
    padding: .5rem;
}