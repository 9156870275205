.enviarMensaje .ql-container {
    height: 402px;
}

.enviarMensaje .ql-toolbar {
    padding: 5px;
    height: auto;
}

.enviarMensaje .toolbar-middle {
    display: inline-block;
    margin-top: 5px;
}

/* Button para enviar mensaje */
.enviarMensaje .btn-ql {
    padding: 3px 6px 3px 12px !important;
    height: auto !important;
    width: auto !important;
}

.enviarMensaje .btn-ql .MuiSvgIcon-root{
    margin-left: 10px !important;
    float: right !important;
}

.enviarMensaje .btn-float {
    float: right;
}