
.passIcon .iconPassword{
    padding: 6px !important;
    position: absolute;

    right: 26px; /* 24px del elemento */
    margin-top: 4px;

    background-color: white;
    color: rgba(0, 0, 0, 0.54);
    border-radius: 50%;
    cursor: pointer;
}
.passIcon .iconPassword:hover{
    background-color: white !important;
}

.passIcon .iconPasswordLogin{
    padding: 6px !important;
    position: absolute;

    right: 26px; /* 24px del elemento */
    margin-top: 4px;

    color: rgba(0, 0, 0, 0.54);
    border-radius: 50%;
    cursor: pointer;
}