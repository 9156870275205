
  /* Tamaño del scroll */
::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }
  
   /* Estilos barra (thumb) de scroll */
  ::-webkit-scrollbar-thumb {
    background: rgb(139, 139, 139);
    border-radius: 4px;
  }
  
  ::-webkit-scrollbar-thumb:active {
    background-color: #858585;
  }
  
  ::-webkit-scrollbar-thumb:hover {
    background: #b3b3b3;
    box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
  }
  
   /* Estilos track de scroll */
  ::-webkit-scrollbar-track {
    background: #d3d3d3;
    border-radius: 4px;
  }
  
  ::-webkit-scrollbar-track:hover, 
  ::-webkit-scrollbar-track:active {
    background: #d4d4d4;
  }

button:focus,
input:focus{
    background-color:transparent;outline:0!important;
}

@media (min-width:  79.375rem){
   .notifications .MuiMenu-paper{
    display: none;
  }
}

