@font-face{
    font-family: "Lato";
    src: url(../../../../../Media/Lato/Lato-Regular.ttf); 
    /*font-family: "Lato";
    src: url('../../../../../Media/Univia/univiapro-regular-webfont.woff2') format('woff2'),
    url('../../../../../Media/Univia/univiapro-regular-webfont.woff') format('woff');*/
}
/* Estilos para MenuUsuario ( Menudrawer ) */
.MuiDrawer-root .MuiListItemIcon-root {
    color: white;
    min-width: 50px !important;
}

.joyride{
    z-index:100;
}
.MuiListItemText-root .MuiTypography-root {
    font-family: "Lato"!important;
}

.imageLogo {
    margin-top: 10px;
    max-width: 100%;
    height: auto;
}