/* Contenedor de la trabla */
.ContenedorDataTable {
    /*position: absolute;*/
    width: 100%;
    margin-bottom: 20px;
}
/* ======================================= Toolbar ======================================= */
.TableroDataTable .MuiToolbar-root {
    background-color: #ecebfcce;
    flex-wrap: wrap;
    padding: 12px;
}

/* Titulo */
.TableroDataTable .MuiToolbar-root .MuiTypography-root { /* .MTableToolbar-title-332 */
    padding-left: 16px;
}

/* Buscador */
.TableroDataTable .MuiToolbar-root .MuiTextField-root { /* .MTableToolbar-searchField-333 */
    padding-right: 16px;
}
/* ------------------------------------- No sirve :( ------------------------------------- */
/* Acciones */
.TableroDataTable .MuiToolbar-root .MTableToolbar-actions-331 { /* .MTableToolbar-actions-331 */
    text-align: center;
    /* color: #03a9f4; */
}
.MTableToolbar-actions-396 span .MuiIconButton-label .MuiSvgIcon-root .MuiTouchRipple-root{
    animation: resplandorAnimation 3s infinite;
}
@keyframes resplandorAnimation {
    0%,100%{
      box-shadow: 0px 0px 10px;
    }
    50%{
    box-shadow: 0px 0px 0px;
    }
  }
/* Acciones: hover */
.TableroDataTable .MuiToolbar-root .MTableToolbar-actions-331 .MuiIconButton-root:hover { /* MTableToolbar-actions-376 */
    background-color: white;
}

/* --------------------------------------------------------------------------------------- */
/* Agregar o eliminar Columnas */
.MuiMenuItem-root span{ /* Texto de cada list */
    font-size: 15px;
}
.MuiMenuItem-root .MuiCheckbox-root { /* padding & tamaño de recuadro */
    padding: 5px;
}
.MuiMenuItem-root .MuiSvgIcon-root {
    font-size: 1.3rem;
}

.MuiTableSortLabel-root{
    color: #4C638E;
    font-weight: bold!important;
}
.MuiTableSortLabel-root:hover{
    color: #697B9F!important;
    font-weight: bold!important;
}
.MuiTableSortLabel-active{
    color: #1572E8!important;
    font-weight: bold!important;
}
/* --------------------------------- @mediaQuery-Toolbar --------------------------------- */
/* --------------------------------------------------------------------------------------- */
@media (max-width: 600px) {
    /* Buscador */
    .TableroDataTable .MuiToolbar-root .MuiTextField-root { /* .MTableToolbar-searchField-333 */
        padding-top: 10px;
        width: 100%;
    }

    /* Acciones */
    .TableroDataTable .MuiToolbar-root .MTableToolbar-actions-331 {
        width: 100%;
        padding-top: 10px;
        text-align: center;
    }
}
/* ======================================== Body ========================================= */
.MuiTableHead-root .MuiTableCell-paddingCheckbox { /* Espacio asignado para checkBox (Interfiere con el texto "Acciones") */
    padding-left: 16px;
}
/* --------------------------------------------------------------------------------------- */
.ContenedorDataTable .MuiSwitch-sizeSmall {
    margin-top: 12px;
}
/* ===================================== TableFooter ===================================== */
.ContenedorDataTable .MuiTableFooter-root {
    background-color:#ecebfcce;
}

.ContenedorDataTable .MuiTableFooter-root .MuiIconButton-root{
    padding: 6px;
}
/* ===================================== Animaciones ===================================== */
.heartbeat {
    -webkit-animation: heartbeat 1.5s ease-in-out infinite both;
	        animation: heartbeat 1.5s ease-in-out infinite both;
}
/* ----------------------------------------------
 * Generated by Animista on 2020-1-28 12:26:34
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */
/**
 * ----------------------------------------
 * animation heartbeat
 * ----------------------------------------
 */
@-webkit-keyframes heartbeat {
    from {
      -webkit-transform: scale(1);
              transform: scale(1);
      -webkit-transform-origin: center center;
              transform-origin: center center;
      -webkit-animation-timing-function: ease-out;
              animation-timing-function: ease-out;
    }
    10% {
      -webkit-transform: scale(0.91);
              transform: scale(0.91);
      -webkit-animation-timing-function: ease-in;
              animation-timing-function: ease-in;
    }
    17% {
      -webkit-transform: scale(0.98);
              transform: scale(0.98);
      -webkit-animation-timing-function: ease-out;
              animation-timing-function: ease-out;
    }
    33% {
      -webkit-transform: scale(0.87);
              transform: scale(0.87);
      -webkit-animation-timing-function: ease-in;
              animation-timing-function: ease-in;
    }
    45% {
      -webkit-transform: scale(1);
              transform: scale(1);
      -webkit-animation-timing-function: ease-out;
              animation-timing-function: ease-out;
    }
  }
@keyframes heartbeat {
    from {
      -webkit-transform: scale(1);
              transform: scale(1);
      -webkit-transform-origin: center center;
              transform-origin: center center;
      -webkit-animation-timing-function: ease-out;
              animation-timing-function: ease-out;
    }
    10% {
      -webkit-transform: scale(0.91);
              transform: scale(0.91);
      -webkit-animation-timing-function: ease-in;
              animation-timing-function: ease-in;
    }
    17% {
      -webkit-transform: scale(0.98);
              transform: scale(0.98);
      -webkit-animation-timing-function: ease-out;
              animation-timing-function: ease-out;
    }
    33% {
      -webkit-transform: scale(0.87);
              transform: scale(0.87);
      -webkit-animation-timing-function: ease-in;
              animation-timing-function: ease-in;
    }
    45% {
      -webkit-transform: scale(1);
              transform: scale(1);
      -webkit-animation-timing-function: ease-out;
              animation-timing-function: ease-out;
    }
}
/* ======================================================================================= */
.MuiTableHead-root {
    display: table-header-group;
    color: #4C638E;
    background: white;
}
.sepa{

    margin-bottom: 2rem!important;
}
.MuiTable-root {
    width: 100%;
    display: table;
    border-spacing: 0;
    border-collapse: collapse;
}

.MuiTableRow-root {
    color: inherit;
    display: table-row;
    outline: 0;
    vertical-align: middle;
}
tbody {
    display: table-row-group;
    vertical-align: middle;
    border-color: inherit;
    background: white;
}
.dinamicTable{
    overflow-x:scroll;
}
.back{
    background-color:#EFF4FD!important;
    max-width: 1550px;
}
.min{
    min-width: 200px;
}
.minIn{
    width: 70%;
}
.negritas{
    font-weight: bold!important;
    table-layout: fixed;
}
.MuiPaper-rounded {
    border-radius: 4px;
}
.MuiPaper-elevation2 {
    box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12);
}
.MuiPaper-root {
    color: rgba(0, 0, 0, 0.87);
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    background-color: #fff;
}
.MuiTableCell-root {
    display: table-cell;
    padding: 16px;
    font-size: 14px;
    text-align: left;
    line-height: 1.43;
    border-bottom: 1px solid rgba(224, 224, 224, 1);
    letter-spacing: 0.01071em;
    vertical-align: inherit;
}
.titleDinamicTable{
    padding: 20px;
    color: black;
}