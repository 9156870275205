
.parametrosFlex{
    display: flex;
    font-family: "Lato";
}

.parametrosIzquierdo{
    width: 100%;
}

.parametrosDerecho{
    width: 150px;
}

.parametrosIzquierdo > p{
    margin-bottom: 1rem !important;
}

.parametrosText{
    color: #697B9F;
    font-size: 1.1rem;
}

.parametrosText > span{
    padding: 0px 8px 0px 6px;
}

/* Componentes */
.radioParametros{
    padding: 8px !important;
}

.radioParametros .MuiSvgIcon-root{
    font-size: 1.1rem !important;
}