.btlctrl:hover {
	cursor: pointer;
}

.btnEditar {
	background-color: transparent;
	border: 1px solid  white;
	border-radius: .25rem;
	color:  white;
	margin: 1px;
	width: auto;
  	height: auto;
	transition: color 0.5s ease-in-out,
		background-color 0.5s  ease-in-out;
	position: relative;
	right: 0px;
}

.btnEditar:hover, .btnEditar:active {
	color: #4C638E;
	background-color:  white;
}

.btnGuardar {
	background-color: transparent;
	border: 1px solid  white;
	border-radius: .25rem;
	color:  white;
	margin: 1px;
	width: auto;
  	height: auto;
	transition: color 0.5s ease-in-out,
		background-color 0.5s  ease-in-out;
	position: relative;
	right: 1px;
}

.btnGuardar:hover, .btnGuardar:active {
	color: #EC8363;
	background-color: white;
}


.btnIzquierda {
	background-color: #818282;
	border: 1px solid  #818282;
	border-radius: .25rem;
	color:  white;
	margin: 1px;
	width: auto;
  	height: auto;
	transition: color 0.5s ease-in-out,
		background-color 0.5s  ease-in-out;
	position: absolute;
	bottom: 5px;
	left: 8px;
}

.btnIzquierda:hover, .btnIzquierda:active {
	color: #818282;
	background-color: white;
}

.btnArriba {
	background-color: #818282;
	border: 1px solid  #818282;
	border-radius: .25rem;
	color:  white;
	margin: 1px;
	width: auto;
  	height: auto;
	transition: color 0.3s ease-in-out,
		background-color 0.3s  ease-in-out;
	position: absolute;
	bottom: 5px;
	left: 31px;
}

.btnArriba:hover, .btnArriba:active {
	color: #818282;
	background-color: white;
}

.btnAgregar {
	background-color: #4C638E;
	border: 1px solid  #4C638E;
	border-radius: .25rem;
	color:  white;
	margin: 1px;
	width: auto;
  	height: auto;
	transition: color 0.5s ease-in-out,
		background-color 0.5s ease-in-out;
	position: absolute;
	bottom: 5px;
	right: 31px;
}
  
.btnAgregar:hover, .btnAgregar:active {
	color:#4C638E;
	background-color: white;
}

.btnEliminar {
	background-color: #E5161F;
	border: 1px solid  #E5161F;
	border-radius: .25rem;
	color:  white;
	margin: 1px;
	width: auto;
  	height: auto;
	transition: color 0.5s ease-in-out,
		background-color 0.5s  ease-in-out;
	position: absolute;
	bottom: 5px;
	right: 8px;
}

.btnEliminar:hover, .btnEliminar:active {
	color: #E5161F;
	background-color: white;
}

.btnInfo {
	background-color: #1572E8;
	border: 1px solid  #1572E8;
	border-radius: .25rem;
	color:  white;
	margin: 1px;
	width: auto;
  	height: auto;
	transition: color 0.5s ease-in-out,
		background-color 0.5s  ease-in-out;
	position: absolute;
	bottom: 5px;
	right: 54px;
}

.btnInfo:hover, .btnInfo:active {
	color: #1572E8;
	background-color:  white;
}

.btnAbajo {
	background-color: #818282;
	border: 1px solid  #818282;
	border-radius: .25rem;
	color:  white;
	margin: 1px;
	width: auto;
  	height: auto;
	transition: color 0.3s ease-in-out,
		background-color 0.3s  ease-in-out;
	position: absolute;
	bottom: 5px;
	left: 54px;
}

.btnAbajo:hover, .btnAbajo:active {
	color: #818282;
	background-color:  white;
}

.btnDerecha {
	background-color: #818282;
	border: 1px solid  #818282;
	border-radius: .25rem;
	color:  white;
	margin: 1px;
	width: auto;
  	height: auto;
	transition: color 0.3s ease-in-out,
		background-color 0.3s  ease-in-out;
	position: absolute;
	bottom: 5px;
	left: 79px;
}

.btnDerecha:hover, .btnDerecha:active {
	color: #818282;
	background-color:  white;
}

.contenedorOrganigramaModal,
.contenedorOrganigrama {
	max-width: 100%;
	width: 100%;
	overflow: auto;
}

.contenedorOrganigrama {
	position: absolute;
}

.orgchart {
	position: relative;
	background-image: none;
	align-content: center;
	width: 100%;
    height: 442px;
	overflow: auto;
	user-select: none;
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	border: 1px dashed rgba(0, 0, 0, 0.226);
}
  
/* node styling */
.orgchart .node1 {
	display: inline-block;
	position: relative;
	margin: 0;
	padding: 3px;
	border: 2px dashed transparent;
	text-align: center;
}

.orgchart .node2 {
	display: inline-block;
	position: relative;
	margin: 0;
	padding: 3px;
	border: 2px dashed transparent;
	text-align: center;
}

.orgchart .node2:hover, 
.orgchart .node1:hover {
	background-color: rgb(250, 221, 0);
	-webkit-transition: .5s;
	-o-transition: .5s;
	transition: .5s;
	cursor: default;
	cursor: grab; cursor : -o-grab; cursor : -moz-grab; cursor : -webkit-grab;
}

.orgchart .node2,
.orgchart .node1 {
	-webkit-transition: all 0.3s;
	-o-transition: all 0.3s;
	transition: all 0.3s;
	top: 0;
	left: 0;
}
  
/* Custom styles */
* {
	box-sizing: border-box;
}
  
ul {
	list-style: none;
	padding: 0;
}
  
h1, h2, h3, h4, h5, h6, p, ul {
	margin: 0px;
}
  
.orgchart ul {
	display: flex;
	flex-direction: row;
}
  
.orgchart .prime-list > li {
	margin: auto;
}
  
.orgchart .node1 {
	position: relative;
	display: flex;
	width: 225px;
	margin-left: auto;
	margin-right: auto;
	margin-bottom: 41px;
}

/* nodo2 ---------------------------------------------------------------------------------------------------------------------------------------------*/
.orgchart .node2 {
	position: relative;
	display: flex;
	width: 225px;
	margin-left: auto;
	margin-right: auto;
	margin-bottom: 41px;
	margin-top: 174px;
}
  
.orgchart ul {
	position: relative;
}
  
.orgchart ul:not(.prime-list):before {
	content: "";
	position: absolute;
	top: -20px;
	left: 1px;
	width: 100%;
	height: 2px;
}

.node1:not(.no-child):after {
	content: "";
	position: absolute;
	bottom: -23px;
	left: 112.5px;
	width: 2px;
	height: 21px;
	background: #b3bec7;
}

/* nodo2 ---------------------------------------------------------------------------------------------------------------------------------------------*/
.node2:not(.no-child):after {
	content: "";
	position: absolute;
	bottom: -23px;
	left: 112.5px;
	width: 2px;
	height: 21px;
	background: #b3bec7;
}

.sub-list .node1:before {
	content: "";
	position: absolute;
	top: -22px;
	left: 112.5px;
	width: 2px;
	height: 20px;
}
  
/* nodo2 ---------------------------------------------------------------------------------------------------------------------------------------------*/
.sub-list .node2:before {
	content: "";
	position: absolute;
	left: 112.5px;
	width: 2px;
	top: -196px;
	height: 194px;
}
  
.sub-list:not(.doshijos):before {
	background: #b3bec7;
}
  
.orgchart .sub-list li {
	position: relative;
}
  
.orgchart .sub-list li:first-child:before,
.orgchart .sub-list li:last-child:before {
	content: "";
	position: absolute;
	top: -20px;
	width: 50%;
	height: 2px;
	background: #FAFAFA;
}
  
.orgchart .sub-list li:first-child:before {
	left: 2px;
}
  
.orgchart .sub-list li:last-child:before {
	right: -2px;
}
  
.orgchart .node2.focused,
.orgchart .node2:hover,
.orgchart .node1.focused,
.orgchart .node1:hover  {
	background-color: #697B9F80;
	border-radius: 4px;
}

/*.orgchart .node2:hover ~ .btnsPosition,
.orgchart .node1:hover * .btnsPosition,
.sub-list .node2:hover * .btnsPosition,
.sub-list2 .node2:hover * .btnsPosition,
.sub-list .no-child:hover * .btnsPosition {
	display: block;
}*/

/*.btnsPosition:hover {
	display: block;
}*/

.orgchart tr.lines .downLine {
	background: #b3bec7;
}
  
.orgchart tr.lines * {
	border-color: #b3bec7 !important;
}

.area-card,
.employee-card {
	font-size: 10px;
	background: #ffffff;
	text-align: left;
    width: 100%;
	border-radius: 4px;
	overflow: hidden;
	-webkit-box-shadow: 0 1px 4px 2px hsla(0, 0%, 80%, .3);
			box-shadow: 0 1px 4px 2px hsla(0, 0%, 80%, .3)
}

.area-card .master-info,
.employee-card .master-info {
	background: #4C638E;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
		align-items: center;
	padding: 6px 6px;
	height: 46px;
}

.area-card .department-info,
.employee-card .department-info {
	background: #1572E8;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
		align-items: center;
	padding: 6px 6px;
	height: 46px;
}

.area-card .master-info .name,
.employee-card .master-info .name,
.area-card .department-info .name,
.employee-card .department-info .name {
	font-size: 12px;
	font-weight: bolder;
	-ms-flex: 1 1;
        flex: 1 1;
    color: #ffffff;
	margin-top: 10px;
	height: 32px;
	text-align: center;
	overflow: auto;
}

.employee-card .info-block,
.area-card .info-block {
	padding: 5px 5px;
	border-top: 4px solid #818282;
}

.area-card .info-block {
	height: 77px;
}

.area-card .info-block .job-title,
.employee-card .info-block .job-title {
	padding: 5px 0;
}
  
.employee-card .info-block .personal-info {
	height: 63px;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: start;
		align-items: flex-start;
}

.area-card .info-block .areas-info {
	height: 63px;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: start;
		align-items: flex-start;
}

.employee-card .info-block .profile-image {
	width: 40px;
	height: 40px;
	-o-object-fit: cover;
		object-fit: cover;
	border-radius: 4px;
}
  
.area-card .info-block .info-wrap,
.employee-card .info-block .info-wrap {
	padding-left: .6em;
	-ms-flex: 1 1;
        flex: 1 1;
	height: 60px;
	overflow: auto
}

.btnsPosition {
	position: absolute;
	right: 105px;
	bottom: -25px;
	/*display: none;*/
}
  
.employee-card .info-block .name,
.employee-card .info-block .num_empleado,
.employee-card .info-block .puesto,
.employee-card .info-block .sueldo,
.employee-card .info-block .identificacion {
	font-size: 10px;
	padding-bottom: .3em;
	margin: 0;
	padding: 0;
}

.area-card .info-block .clave,
.area-card .info-block .proyecto,
.area-card .info-block .plazas-ocupadas,
.area-card .info-block .plazas-libres {
  font-size: 10px;
  padding-bottom: .3em;
  margin: 0;
  padding: 0;
}
