.containerChat{
  border-width: 1px;
  border-style: solid;
  border-color: #1572E8;
  border-radius: 5px;
  padding: 0px;
  background-color: white;
}

.usuario{
  padding: 8px 5px 0 10px;
  margin-top: 5px;
  margin-right: 5px;
  width: 100%;
  height: 37px;
  border-radius: 5px;
  background-color: #fff;
  color: #1572E8;
  font-weight: bold;
  text-align: left;
  font-family: "Lato";
}

.button-head{
  color: #52547B;
  text-align: center;
}

.button-head:hover{
  color: #1572E8;
  font-weight: bold;
  text-align: center;
  cursor: pointer;
}

.incoming_msg_img {
  color: #ff5e00;
  font-size: 9px;
  display: inline-block;
  width: 15%;
}

.outgoing_msg_img {
  color: #52547B;
  font-size: 9px;
  display: inline-block;
  width: 15%;
}

.received_msg {
  display: inline-block;
  padding: 0 0 0 10px;
  vertical-align: top;
  width: 85%;
 }

.received_withd_msg p {
  overflow-wrap: break-word;
  background: #E4E5E6 none repeat scroll 0 0;
  border-radius: 10px;
  color: #414040;
  font-size: 11px;
  margin: 0;
  padding: 5px 5px 5px 5px;
  width: 100%;
}

.time_date {
  color: #747474;
  display: block;
  font-size: 9px;
  margin: 4px 0 0;
}

.received_withd_msg { width: 90%; }

.mesgs {
  float: left;
  margin: 0 5px 5px 5px;
  width: 96%;
  background-color: white;
  border-top: 1px solid #c4c4c4;

  /*border-radius: 10px;
  border-width: 1px;
  border-style: solid;
  border-color: #58577F;*/
}

.sent_msg {
  float: left;
  width: 70%;
  margin-left: 10%;
  margin-right: 2%;
}

.sent_msg p {
  overflow-wrap: break-word;
  background: #1572E8 none repeat scroll 0 0;
  border-radius: 10px;
  font-size: 11px;
  margin: 0;
  color:#fff;
  padding: 5px 5px 5px 5px;
  width: 100%;
}

.outgoing_msg {
  overflow: hidden;
  margin: 5px 0 5px 0;
}

.incoming_msg{ overflow:hidden; margin:5px 0 5px 0;}

.input_msg_write input {
  border-radius: 15px;
  background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
  border: medium none;
  color: #000000;
  font-size: 11px;
  height: 38px;
  width: 89%;
  font-family: "Lato";
  padding-left: 5px;
}

.type_msg {
  border-top: 1px solid #c4c4c4;
  position: relative;
}

.msg_send_btn {
  background: none repeat scroll 0 0;
  border: medium none;
  position: absolute;
  right: 0;
  top: 8px;
  width: 30px;
  color: #4C638E;
}

.msg_send_btn:hover {
  color: #1572E8;
}

.msg_history {
  height: 217px;
  overflow-y: auto;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-right: 5px;
  margin-left: 5px;
}

#typing{
  background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
  color: #000000;
  font-size: 10px;
  width: 100%;
  height: 14px;
  margin-left: 5px;
}

.chatTooltip{
  position:absolute;
  z-index:10000;
  left:6px;
  top:0;
}

.minimizaVentana{
  transform: scaleY(0);
  transition: all 0ms ease;
  /*transform-origin: top;*/
  max-height: 0;
}

.parpadea {
  margin-top: 0px;
  margin-right: 5px;
  padding-top: 10px;
  width: 100%;
  height: 42px;

  background-color: #1572E8;
  color: white;
  animation-name: parpadeo;
  animation-duration: 2s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;

  -webkit-animation-name:parpadeo;
  -webkit-animation-duration: 2s;
  -webkit-animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
}

@-moz-keyframes parpadeo{  
  0% { opacity: 1.0; }
  50% { opacity: 0.0; }
  100% { opacity: 1.0; }
}

@-webkit-keyframes parpadeo {  
  0% { opacity: 1.0; }
  50% { opacity: 0.0; }
  100% { opacity: 1.0; }
}

@keyframes parpadeo {  
  0% { opacity: 1.0; }
  50% { opacity: 0.0; }
  100% { opacity: 1.0; }
}