/* -- Header btn-color */
.mensajesHeader .btn-color{
    margin-top: 0rem !important;
    margin-bottom: 0rem !important;
    padding: 0.4rem 0.8rem 0.4rem 0.8rem !important;
    font-size: 0.75rem !important;
    justify-content: space-between !important;
}

.mensajesHeader .MuiSvgIcon-root{
    font-size: 1.2rem !important;
    margin-right: 0.8rem !important;
}

.mensajesAction .MuiSvgIcon-root{
    font-size: 14px !important;
    margin-right: 0.8rem !important;
}

/* -- Button incolor */
.btn-incolor{
    color: #757575 !important;
    padding: 0.4rem 0.8rem 0.4rem 0.8rem !important;
    font-size: 0.8rem !important;
    text-transform: capitalize !important;
    font-family: "Lato" !important;
}

.btn-incolor:hover{
    color: #1572E8 !important;
}

.btn-seleccionado{
    background-color: #FFFFFF !important;
    color: #1572E8 !important;
}

/* -- mensajesFlex */
.mensajesFlex{
    display: flex;
    align-items: center;
}

/* -- Avatar */
.mensajesAvatar{
    width: 25px !important;
    height: 25px !important;
    margin-left: 1px;
    margin-right: 7px;
}

.contenidoAvatar{
    width: 36px !important;
    height: 36px !important;
    margin-right: 10px;
}

.avatarLeido{
    opacity: 70% !important;
}

/* -- CheckBox */
.mensajesCheckBox{
    padding: 6px !important;
    margin-right: 4px !important;
}

.mensajesCheckBox .MuiSvgIcon-root{
    font-size: 14px;
}

/* -- Punto */
.mensajesPunto{
    width: 7px;
    height: 7px;
    margin-left: 11px;
    margin-right: 16px;
    border-radius: 50%;
    background: #7793EF;
}

.marginAsunto{
    margin-right: 32px;
}

/* -- Hover status de mensaje ------------------------------------------------------- */
.noLeido{
    background-color: #F5F6F7;
}

.siLeido{
    background-color: #ffffff;
}

.seleccionado{
    background-color: #D0E1FF;
    border-left: 4px solid #1572E8;
}

/* -- Texto en Bandejas/Mensajes ---------------------------------------------------- */
.toNoLeido{
    font-size: 0.75rem;
    color: #2F2E2E;
    font-weight: bold;
}
.toLeido{
    font-size: 0.75rem;
    color: #2F2E2E;
}
/* ---------------- Solo para mostrar Fecha ---------------- */
.mensajesFecha{
    float: right;
    font-size: 0.65rem;
    color: #707070;
}

.divAvatarFrom{
    width: 100%;
}
.divFecha{
    width: 150px;
}
/* --------------------------------------------------------- */
.mensajesAsunto{
    width: 200px;
    overflow: hidden;
    white-space: nowrap; /* Evita el salto de linea en los textos */
    text-overflow: ellipsis; /* En caso de no caber todo el texto coloca ...  */
}

.asuntoNoLeido{
    font-size: 0.75rem;
    color: #7793EF;
    font-weight: bold;
}
.asuntoLeido{
    font-size: 0.75rem;
    color: #2F2E2E;
}

.mensajesMensaje{
    font-size: 0.75rem;
    color: #707070;

    width: 200px;
    overflow: hidden;
    white-space: nowrap; /* Evita el salto de linea en los textos */
    text-overflow: ellipsis; /* En caso de no caber todo el texto coloca ...  */
}

/* --Texto en Mostrar Mensaje */
.fromNombreMensaje{
    font-size: 0.75rem;
    color: #2F2E2E;
    font-weight: bold;
}

.fromMensaje{
    font-size: 0.75rem;
    color: #707070;
}

.paraMensaje{
    font-size: 0.75rem;
    color: #707070;
    font-weight: bold;
}