.styles_modal__gNwvD {
    border-radius: 5px;
}
.styles_overlay__CLSq- {
    padding: 10% 0;
}

@media (max-width: 600px) {
    .styles_overlay__CLSq- {
        padding-left: 24px;
        display: block;
    }
}